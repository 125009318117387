import { device } from '@util/responsive'
import { FilterIcon } from '@global/icons/Filter'
import React, { useImperativeHandle, useState } from 'react'
import { trackEvent } from '@util/ga'
import { useSearch } from '@contexts/search'
import { useMediaQuery } from '@hooks/mediaQuery'
import dynamic from 'next/dynamic'
import { Modal } from '@components/global/Modal'
import styled from 'styled-components'
import { Spinner } from '@components/global/Spinner'

const DesktopFilterModal = dynamic(
    () => import('./DesktopFilterModal').then(({ DesktopFilterModal }) => DesktopFilterModal),
    { ssr: false, loading: () => <Spinner /> },
)

const MobileFilterModal = dynamic(
    () => import('./MobileFilterModal').then(({ MobileFilterModal }) => MobileFilterModal),
    { ssr: false, loading: () => <Spinner /> },
)

export const Filter = React.forwardRef((_, ref) => {
    const isDesktop = useMediaQuery(device.laptop)
    const [isOpen, setIsOpen] = useState(false)
    const openFromParent = () => {
        setIsOpen(true)
    }
    useImperativeHandle(ref, () => ({
        openFromParent,
    }))
    const { datePickerOverlay, setDatepickerOverlay } = useSearch()
    return (
        <div>
            <FilterWrapper
                data-testid="filter-button"
                onClick={() => {
                    setIsOpen(true)
                    trackEvent({
                        action: 'Filters-open',
                        category: 'BrowseDR',
                    })
                    datePickerOverlay && setDatepickerOverlay(false)
                }}
            >
                <FilterIcon />
            </FilterWrapper>
            {isOpen &&
                (isDesktop ? (
                    <Modal setModal={setIsOpen} wrapperComponent={ModalContainer}>
                        <DesktopModalWrapper>
                            <DesktopFilterModal setModal={setIsOpen} />
                        </DesktopModalWrapper>
                    </Modal>
                ) : (
                    <Modal wrapperComponent={ModalWrapper} setModal={setIsOpen}>
                        <MobileFilterModal setModal={setIsOpen} />
                    </Modal>
                ))}
        </div>
    )
})

Filter.displayName = 'Filter'

const FilterWrapper = styled.div`
    position: fixed;
    z-index: 30;
    bottom: 1rem;
    inset-inline-end: 1rem;
    background: var(--primaryColor);
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-color: rgb(203 71 71 / 90%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
    border-radius: 50px;
    cursor: pointer;
    @media ${device.laptop} {
        display: 'flex';
        position: 'absolute';
        inset-inline-end: '-8rem';
        top: '50%';
    }
`
const DesktopModalWrapper = styled.div`
    background: white;
    height: fit-content;
    width: 40vw;
    min-width: 450px;
    max-width: 600px;
    max-height: var(--maxModalHeight);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
`
const ModalWrapper = styled.div`
    z-index: 50;
    inset: 0;
    background: white;
    padding-bottom: 5rem;
`
