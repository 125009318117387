import styled from 'styled-components'
import { useGlobalState } from '@contexts/global'
import { useTranslation } from 'next-i18next'
import { ProductType } from '@service/configuration.types'
import { useCallback, useState } from 'react'
import { DateTimeSelectorTypes, GATrackingActions, GATrackingCategories } from '@util/enums'
import { TimeFilterMonthly } from '@components/modules/Search/MobileBookingQuickFilter/components/TimeFilterMonthly'
import { TimeFilterDaily } from '@components/modules/Search/MobileBookingQuickFilter/components/TimeFilterDaily'
import { Tab, TabSubTitle, TabTitle } from '../DesktopProductSwitcher'
import { device } from '@util/responsive'
import { useMediaQuery } from '@hooks/mediaQuery'
import { useProductSwitcher } from '../useProductSwitcher'
import dynamic from 'next/dynamic'
import { TimeSelector } from '@components/global/TimeSelector'
import Datepicker from '@components/modules/Calendar/Datepicker'
import { trackEvent } from '@util/ga'
import { useAuth } from '@contexts/auth'

const Filter = dynamic(() => import('@components/modules/Car/Filter').then(({ Filter }) => Filter), {
    ssr: false,
})

export const MobileBookingQuickFilter = ({ isThereActiveBooking }: { isThereActiveBooking: boolean | null }) => {
    const { t } = useTranslation()
    const { bookingState } = useGlobalState()
    const [isCalendarModalOpened, setIsCalendarModalOpened] = useState(false)
    const [activeSpecTimeOption, setActiveSpecTimeOption] = useState<DateTimeSelectorTypes | null>(null)
    const { onTabSelected } = useProductSwitcher()
    const isMobileL = useMediaQuery(device.mobileL)
    const isDesktop = useMediaQuery(device.laptop)
    const { user } = useAuth()

    const selectedProductType = bookingState.productType
    const isDaily = selectedProductType === ProductType.DAILY

    const handleCalendarModalClick = useCallback(() => {
        if (!isCalendarModalOpened) setIsCalendarModalOpened(true)
    }, [isCalendarModalOpened, setIsCalendarModalOpened])

    return (
        <>
            {isThereActiveBooking && <Title>{t('timeFilterWidget.bookAnother', 'Book another car')}</Title>}
            <MobileTimeFilterWrapper>
                <ProductSelectionTabsWrapper>
                    <ProductSelectionTab
                        active={isDaily}
                        onClick={() => {
                            onTabSelected(ProductType.DAILY)
                            trackEvent({
                                action: GATrackingActions.H_DAILY_CLICK,
                                category: GATrackingCategories.HOMETAB,
                                value: {
                                    userId: user?.userId || '',
                                    careemId: user?.careemId || '',
                                    isDesktop: isDesktop,
                                },
                            })
                        }}
                    >
                        <Tab>
                            <TabTitle active={isDaily}>{t('timeFilterWidget.tabTitleLeft', 'short term')}</TabTitle>
                            <TabSubTitle>{t('timeFilterWidget.tabSubTitleLeft', 'days and weeks')}</TabSubTitle>
                        </Tab>
                    </ProductSelectionTab>
                    <ProductSelectionTab
                        active={!isDaily}
                        onClick={() => {
                            onTabSelected(ProductType.MONTHLY)
                            trackEvent({
                                action: GATrackingActions.H_MONTHLY_CLICK,
                                category: GATrackingCategories.HOMETAB,
                                value: {
                                    userId: user?.userId || '',
                                    careemId: user?.careemId || '',
                                    isDesktop: isDesktop,
                                },
                            })
                        }}
                    >
                        <Tab>
                            <TabTitle active={!isDaily}>{t('timeFilterWidget.tabTitleRight', 'long term')}</TabTitle>
                            <TabSubTitle>{t('timeFilterWidget.tabSubTitleRight', '1 month+')}</TabSubTitle>
                        </Tab>
                    </ProductSelectionTab>
                </ProductSelectionTabsWrapper>
                <FilterContent>
                    {selectedProductType === ProductType.DAILY && (
                        <>
                            <TimeFilterDaily
                                setActiveSpecTimeOption={setActiveSpecTimeOption}
                                handleCalendarModalClick={handleCalendarModalClick}
                                showIconForDate={isMobileL}
                            />
                        </>
                    )}
                    {selectedProductType === ProductType.MONTHLY && (
                        <TimeFilterMonthly
                            type={DateTimeSelectorTypes.from}
                            label={t('timeFilterWidget.handover', 'Handover')}
                            handleCalendarModalClick={handleCalendarModalClick}
                        />
                    )}
                </FilterContent>
                {isCalendarModalOpened && (
                    <Datepicker
                        setTimeModal={setIsCalendarModalOpened}
                        isSingleDaySelectionMode={selectedProductType === ProductType.MONTHLY}
                    />
                )}
                {activeSpecTimeOption && (
                    <TimeSelector specTime={activeSpecTimeOption} setTimeModal={() => setActiveSpecTimeOption(null)} />
                )}
                <Filter />
            </MobileTimeFilterWrapper>
        </>
    )
}

const MobileTimeFilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`

const ProductSelectionTabsWrapper = styled.div`
    display: flex;
    z-index: 10;
    position: relative;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);
`

const ProductSelectionTab = styled.div<{ active: boolean }>`
    display: flex;
    width: 50%;
    background: ${(props) => (props.active ? 'white' : 'rgb(255,255,255,0.2)')};
    color: ${(props) => (props.active ? 'var(--primaryColor)' : 'white')};
    padding: 8px var(--padding);
    transition: all cubic-bezier(0.88, -0.35, 0.565, 1.35) 0.2s;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    border-radius: 16px 16px 0 0;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
`

const FilterContent = styled.div`
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    border-radius: 0 0 16px 16px;
    column-gap: 1rem;
    padding: 16px;
`

const Title = styled.h2`
    color: var(--white);
    padding: 0;
    font-weight: 800;
    font-size: 14px;
`
