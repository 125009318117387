import { bookingService } from '@service/booking'
import { add } from 'date-fns'
import { CarType, ListingFilter } from '@service/booking.types'
import { useRouter } from 'next/router'
import { CitySettingsResponse, Currency, ProductType } from '@service/configuration.types'
import { useCallback, useEffect, useState } from 'react'
import { useCarTypeFilterValues } from '@components/modules/Car/useCarTypeFilterValues'
import { ParsedUrlQuery } from 'querystring'
import { useRanger } from 'react-ranger'
import { throttle } from 'lodash'
import { useLocaleConfig } from '@contexts/config'
import { useDeepCompareMemo } from '@hooks/useDeepCompare'
import { useCarMakeFilterValues } from './useCarMakeFilterValues'
import { useCarModelFilterValues } from './useCarModelFilterValues'
import { useCarFuelTypesFilterValues } from './useCarFuelTypesFilterValues'
import { useCarFeaturesFilterValues } from './useCarFeaturesFilterValues'
import { useCarGearboxFilterValues } from './useCarGearboxFilterValues'
import { submitFilterToGA } from '@components/modules/Car/utils'

import { getCityByCityCode, getCityCodeByCity } from '@util/localization'
import { CarFilterActions, City, TranslationPrefix } from '@util/enums'
import { convertToTranslationKey, getValueFromArrayQueryType } from '@util/functions'
import { useTranslation } from 'react-i18next'
import { SelectListItem } from './Filter/Filter.types'
import { useAuth } from '@contexts/auth'

const defaultTo = (value: unknown, defVal: number): number => {
    if (typeof value === 'string') {
        return Number(value) ?? defVal
    } else if (typeof value === 'number') {
        return value
    } else {
        return defVal
    }
}

interface ListingQuery {
    page?: number
    cityCode?: string
    pageSize?: number
    minDailyPrice?: number
    maxDailyPrice?: number
    minSeats?: number
    maxSeats?: number
    carType?: CarType[]
    from: string
    city: string
    to: string
    productType?: ProductType
    makeIds?: number[]
    modelIds?: number[]
    engineTypes?: string[]
    gearboxTypes?: string[]
    carFeatureIds?: string[]
}

function getPriceBoundsForProduct(
    productType: ProductType = ProductType.DAILY,
    query: ParsedUrlQuery,
    citySettings: CitySettingsResponse | undefined,
) {
    return {
        minPrice: defaultTo(citySettings?.minSearchPrice, productType === ProductType.DAILY ? 10 : 100),
        maxPrice: defaultTo(citySettings?.maxSearchPrice, productType === ProductType.DAILY ? 1000 : 30000),
    }
}

export const getListingFilterFromQuery = (
    query: ParsedUrlQuery,
    citySettings?: CitySettingsResponse | undefined,
    enabledNewFlow?: boolean,
): ListingFilter => {
    const productType = (query.productType as ProductType) || ProductType.DAILY
    const { minPrice, maxPrice } = getPriceBoundsForProduct(productType, query, citySettings)

    const carType: CarType[] = getValueFromArrayQueryType(query.carType)
    const makeIds: number[] = getValueFromArrayQueryType(query.makeIds)
    const modelIds: number[] = getValueFromArrayQueryType(query.modelIds)
    const engineTypes: string[] = getValueFromArrayQueryType(query.engineTypes)
    const gearboxTypes: string[] = getValueFromArrayQueryType(query.gearboxTypes)
    const carFeatureIds: string[] = getValueFromArrayQueryType(query.carFeatureIds)

    const date = new Date()
    date.setHours(0, 0, 0, 0)

    const queryFromDate = query.from ? new Date(query.from as string) : date
    const handbackTime =
        productType === ProductType.MONTHLY
            ? add(queryFromDate, { months: 1 }).toISOString()
            : ((query.to || add(date, { days: 4 }).toISOString()) as string)

    return {
        page: defaultTo(query.page, 1),
        pageSize: defaultTo(query.pageSize, 10),
        cityCode: getCityCodeByCity(query.city as City),
        handoverTime: (query.from || date.toISOString()) as string,
        handbackTime: handbackTime,
        minSeats: defaultTo(query.minSeats, 2),
        maxSeats: defaultTo(query.maxSeats, 10),
        maxDailyPrice: defaultTo(query.maxDailyPrice, maxPrice),
        minDailyPrice: defaultTo(query.minDailyPrice, minPrice),
        carType,
        productType,
        makeIds,
        modelIds,
        engineTypes,
        gearboxTypes,
        carFeatureIds,
        isPaymentRevampEnabled: enabledNewFlow,
    }
}

export const getQueryFromListingFilter = (filter: ListingFilter): ListingQuery => {
    return {
        page: filter.page,
        city: getCityByCityCode(filter.cityCode),
        pageSize: filter.pageSize,
        cityCode: filter.cityCode,
        from: filter.handoverTime as string,
        to: filter.handbackTime as string,
        minSeats: filter.minSeats,
        maxSeats: filter.maxSeats,
        maxDailyPrice: filter.maxDailyPrice,
        minDailyPrice: filter.minDailyPrice,
        carType: filter.carType,
        productType: filter.productType,
        makeIds: filter.makeIds,
        modelIds: filter.modelIds,
        engineTypes: filter.engineTypes,
        gearboxTypes: filter.gearboxTypes,
        carFeatureIds: filter.carFeatureIds,
    }
}

const useRange = ([min, max]: [number, number], [minBound, maxBound]: [number, number], step = 1) => {
    const [range, setRange] = useState<number[]>([min, max])

    useEffect(() => {
        setRange([min, max])
    }, [min, max])

    const reset = useCallback(() => {
        setRange([minBound, maxBound])
    }, [minBound, maxBound])
    const rangeConfig = useRanger({
        min: minBound,
        max: maxBound,
        values: range,
        stepSize: step,
        onChange: throttle(setRange, 1000),
    })
    return { rangeConfig, range, minBound, maxBound, reset }
}

export const useListingQuery = () => {
    const { t } = useTranslation()
    const { query } = useRouter()
    const { citySettings } = useLocaleConfig()
    const { isEnabledNewBookingFlow } = useAuth()

    const listingParams = getListingFilterFromQuery(query, citySettings, isEnabledNewBookingFlow)
    const { minPrice, maxPrice } = getPriceBoundsForProduct(listingParams.productType, query, citySettings)
    const priceRange = useRange(
        [listingParams.minDailyPrice ?? minPrice, listingParams.maxDailyPrice ?? maxPrice],
        [minPrice, maxPrice],
        listingParams.productType === ProductType.DAILY ? 200 : 1000,
    )
    const seatsRange = useRange([listingParams.minSeats as number, listingParams.maxSeats as number], [2, 10], 1)

    const { carMakeData, carMakeFiltered, carMakeDispatch } = useCarMakeFilterValues(
        listingParams.productType,
        listingParams.makeIds,
    )
    const { carModelData, carModelFiltered, carModelDispatch } = useCarModelFilterValues(
        listingParams.productType,
        listingParams.modelIds,
        carMakeFiltered,
    )
    const { carFuelData, carFuelFiltered, carFuelDispatch } = useCarFuelTypesFilterValues(listingParams.engineTypes)
    const { carGearboxData, carGearboxFiltered, carGearboxDispatch } = useCarGearboxFilterValues(
        listingParams.gearboxTypes,
    )
    const { carTypeFilterQuery, carTypeFilter, carTypeDispatch } = useCarTypeFilterValues(listingParams.carType)
    const { carFeaturesData, carFeaturesFiltered, carFeaturesDispatch, carFeaturesQuery } = useCarFeaturesFilterValues(
        listingParams.productType,
        listingParams.carFeatureIds,
    )

    const listingFilter = useDeepCompareMemo(() => {
        return {
            ...listingParams,
            minDailyPrice: priceRange.range[0],
            maxDailyPrice: priceRange.range[1],
            carType: carTypeFilterQuery,
            makeIds: carMakeFiltered,
            modelIds: carModelFiltered,
            engineTypes: carFuelFiltered,
            gearboxTypes: carGearboxFiltered,
            carFeatureIds: carFeaturesQuery.map((x) => x.id.toString()),
        }
    }, [
        listingParams,
        priceRange.range[0],
        priceRange.range[1],
        seatsRange.range,
        carTypeFilterQuery,
        carMakeFiltered,
        carModelFiltered,
        carFuelFiltered,
        carGearboxFiltered,
        carFeaturesQuery,
    ])

    const [filterResultsNumber, setFilterResultsNumber] = useState(0)

    const refetchListingsAndSetResultsNumber = useCallback(async () => {
        const { data } = await bookingService.getListings({
            ...listingFilter,
            carType: listingFilter.carType,
            page: 1,
        })
        setFilterResultsNumber(data?.paginationInfo.totalElements ?? 0)
    }, [listingFilter])

    const handleApply = () => {
        const { city: _, ...filteredQueryWithoutCity } = getQueryFromListingFilter(listingFilter)
        submitFilterToGA(filteredQueryWithoutCity)
    }

    const changeTransmission = useCallback(
        (value: SelectListItem[]) =>
            carGearboxDispatch({ type: CarFilterActions.set, value: value.map((x) => `${x?.id || ''}`) }),
        [carGearboxDispatch],
    )

    const changeMake = useCallback(
        (value: SelectListItem[]) => carMakeDispatch({ type: CarFilterActions.set, value: value.map((x) => +x.id) }),
        [carMakeDispatch],
    )

    const changeModel = useCallback(
        (value: SelectListItem[]) => carModelDispatch({ type: CarFilterActions.set, value: value.map((x) => +x.id) }),
        [carModelDispatch],
    )

    const changeFuelType = useCallback(
        (value: SelectListItem[]) =>
            carFuelDispatch({ type: CarFilterActions.set, value: value.map((x) => `${x?.id || ''}`) }),
        [carFuelDispatch],
    )

    const changeCarFeatures = useCallback(
        (id: number, value: boolean) =>
            carFeaturesDispatch({
                type: CarFilterActions.set,
                id,
                value,
            }),
        [carFeaturesDispatch],
    )

    const priceRangeSuffix = useCallback(() => {
        const currency = citySettings?.currency || Currency.AED
        const currencyTranslated = t(convertToTranslationKey(currency, TranslationPrefix.currency), currency)
        return currencyTranslated
    }, [citySettings?.currency, t])

    const reset = useCallback(() => {
        carMakeDispatch({ type: CarFilterActions.reset })
        carFeaturesDispatch({ type: CarFilterActions.reset })
        carModelDispatch({ type: CarFilterActions.reset })
        carFuelDispatch({ type: CarFilterActions.reset })
        carGearboxDispatch({ type: CarFilterActions.reset })
        Object.keys(carTypeFilter).map((key) => {
            carTypeDispatch({ type: CarFilterActions.input, name: key, value: false })
        })
        priceRange.reset()
    }, [
        carFeaturesDispatch,
        carFuelDispatch,
        carGearboxDispatch,
        carMakeDispatch,
        carModelDispatch,
        carTypeDispatch,
        carTypeFilter,
        priceRange,
    ])

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            const { data } = await bookingService.getListings({
                ...listingFilter,
                carType: listingFilter.carType,
                page: 1,
            })
            isSubscribed && setFilterResultsNumber(data?.paginationInfo.totalElements ?? 0)
        }
        fetchData()
        return () => {
            isSubscribed = false
        }
    }, [listingFilter])

    return {
        makes: carMakeData || [],
        models: carModelData || [],
        carFeatures: carFeaturesData || [],
        gearBoxTypes: carGearboxData,
        engineTypes: carFuelData,
        listingFilter,
        listingQuery: getQueryFromListingFilter(listingFilter),
        filterResultsNumber,
        priceRange,
        seatsRange,
        carMakeFiltered,
        carModelFiltered,
        carFeaturesFiltered,
        carFeaturesQuery,
        carFuelFiltered,
        carGearboxFiltered,
        carTypeFilter,
        carTypeDispatch,
        carMakeDispatch,
        carModelDispatch,
        carFuelDispatch,
        carGearboxDispatch,
        carFeaturesDispatch,
        refetchListingsAndSetResultsNumber,
        reset,
        handleApply,
        changeTransmission,
        changeMake,
        changeModel,
        changeFuelType,
        changeCarFeatures,
        priceRangeSuffix,
    }
}
